import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Container,
  Link,
  Paper,
  Slide,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Facebook from '@material-ui/icons/Facebook'
import Instagram from '@material-ui/icons/Instagram'
import Pinterest from '@material-ui/icons/Pinterest'
import YouTube from '@material-ui/icons/YouTube'

import { withClickTracker } from 'artkive/components/Tracker'
import { REFERRAL_MODAL } from 'artkive/constants/tracker/mainNavigation'
import useReferralCta from 'artkive/hooks/useReferralCta'
import routes from 'artkive/utils/routes'

import bubbles from 'images/ecom/get-started/bubbles.png'

import AccentLink from '../Navbar/AccentLink'
import BooksIcon from '../Navbar/BooksIcon'
import OrderTrackingIcon from '../Navbar/OrderTrackingIcon'

import MenuLink from './MenuLink'

const TrackAccentLink = withClickTracker(AccentLink)

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1.125rem',
    lineHeight: 1.4,
    overflowY: 'scroll',
    height: '100vh',
  },
  icon: {
    fontSize: 24,
  },
  getStartedWrapper: {
    position: 'relative',
    zIndex: 1,
    '&::before': {
      position: 'absolute',
      content: "' '",
      background: `url(${bubbles}) no-repeat`,
      transform: 'rotate(0.25turn)',
      backgroundSize: '99px 192px',
      width: 99,
      height: 192,
      left: -75,
      top: -75,
      zIndex: 0,
    },
    '&::after': {
      position: 'absolute',
      content: "' '",
      background: `url(${bubbles}) no-repeat`,
      backgroundSize: '99px 192px',
      transform: 'rotate(-0.25turn)',
      width: 99,
      height: 192,
      right: -75,
      top: -75,
      zIndex: 0,
    },
  },
}))

const headerPadding = '48px'

const MobileMenu = ({ isOpen, onNavigate }) => {
  const classes = useStyles()
  const openReferralModal = useReferralCta()

  const handleGet20Click = useCallback((event) => {
    onNavigate(event)
    openReferralModal(event)
  }, [onNavigate, openReferralModal])

  return (
    <Slide direction={'down'} in={isOpen} timeout={600} style={{ zIndex: '1000' }}>
      <Box top={headerPadding} position={'fixed'} left={0} width={'100%'}>
        <Paper elevation={0} className={classes.root}>
          <Container>
            <Box py={headerPadding} textAlign={'center'}>
              <Box mb={2}>
                <MenuLink isPrimary={true} href={routes.books} variant={'h5'}>Art Books</MenuLink>
              </Box>
              <Box mb={2}>
                <MenuLink isPrimary={true} href={routes.mosaics} variant={'h5'}>Framed Mosaics</MenuLink>
              </Box>
              <Box mb={2}>
                <MenuLink isPrimary={true} href={routes.cardsByArtkive} variant={'h5'}>Cards by Artkive</MenuLink>
              </Box>

              <Box mt={5} />

              <Box mb={2.5}><MenuLink href={routes.pricing}>Pricing</MenuLink></Box>
              <Box mb={2.5}><MenuLink href={routes.aboutUs}>About Us</MenuLink></Box>
              <Box mb={2.5}><MenuLink href={routes.ourProcess}>Our Process</MenuLink></Box>
              {/* <Box mb={2.5}><MenuLink href={routes.giftCards}>Gift Cards</MenuLink></Box> */}

              <Box mb={2.5}><MenuLink href={routes.plans}>Memberships</MenuLink></Box>
              <Box mb={2.5}><MenuLink href={routes.giftCards}>Gift Cards</MenuLink></Box>
              <Box mb={2.5}>
                <TrackAccentLink
                  key={'get-20'}
                  className={classes.navLink}
                  onClick={handleGet20Click}
                  trackerEvent={REFERRAL_MODAL.CTA_CLICK}
                >
                  Get $20
                </TrackAccentLink>
              </Box>

              <Box mb={2.5}>
                <MenuLink
                  component={Button}
                  startIcon={<OrderTrackingIcon className={classes.icon} />}
                  href={routes.orderTracking}
                >
                  Track My Order
                </MenuLink>
              </Box>
              <Box mb={2.5}>
                <MenuLink
                  component={Button}
                  size={'large'}
                  startIcon={<BooksIcon className={classes.icon} />}
                  href={routes.accountBooks}
                >
                  My Books
                </MenuLink>
              </Box>

              <Box className={classes.getStartedWrapper} mt={6}>
                <Button
                  color={'primary'}
                  href={routes.boxCheckout}
                  variant={'contained'}
                  size={'large'}
                  style={{ zIndex: 1 }}
                >
                  Get My Box
                </Button>
              </Box>

              <Box mt={9} display={'flex'} justifyContent={'center'}>
                <Box mr={2}>
                  <Link color={'secondary'} href={routes.instagram} target={'_blank'}>
                    <Instagram fontSize={'small'} />
                  </Link>
                </Box>
                <Box mr={2}>
                  <Link color={'secondary'} href={routes.facebook} target={'_blank'}>
                    <Facebook fontSize={'small'} />
                  </Link>
                </Box>
                <Box mr={2}>
                  <Link color={'secondary'} href={routes.pinterest} target={'_blank'}>
                    <Pinterest fontSize={'small'} />
                  </Link>
                </Box>
                <Box>
                  <Link color={'secondary'} href={routes.youtube} target={'_blank'}>
                    <YouTube fontSize={'small'} />
                  </Link>
                </Box>
              </Box>
              <Box textAlign={'center'} fontSize={'12px'} color={'text.secondary'} mt={2}>
                &copy; {(new Date().getFullYear())} Artkive All Rights Reserved.
              </Box>
            </Box>
          </Container>
        </Paper>
      </Box>
    </Slide>
  )
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  onNavigate: PropTypes.func,
}

MobileMenu.defaultProps = {
  isOpen: false,
  onNavigate: () => {},
}

export default MobileMenu
