import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ADDONS } from 'common/utils/conciergeProduct.constants'
import SummaryDetailsBox from 'artkive/components/Checkout/SummaryDetailsBox'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import formatNumber from 'artkive/utils/formatNumber'
import theme from 'artkive/utils/theme'

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    backgroundColor: '#FDE4A5',
  },
  content: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FEF6E1;',
  },
  listItemRoot: {
    padding: 0,
    color: theme.colors.black,
    fontWeight: 500,
  },
  listRoot: {
    padding: 0,
  },
  refundText: {
    color: theme.colors.green.main,
  },
  text: {
    color: theme.colors.black,
    fontWeight: 500,
  },
  name: {
    paddingRight: spacing(6),
  },
  price: {
    right: 0,
  },
}))

export const buildOrderDetailsList = (addOns, activeProcessing, price) => {
  const elements = []
  const order = [ADDONS.RETURN_ART, ADDONS.RETURN_USB, ADDONS.EXTRA_BOOKS]
  order.forEach((kind) => {
    addOns.forEach((addon) => {
      if (addon.kind === kind) {
        elements.push(addon)
      }
    })
  })

  if (activeProcessing) {
    elements.push({
      name: `${activeProcessing.name} Processing Time`,
      price: activeProcessing.price,
    })
  }

  if (price.shipping_protection_price) {
    elements.push({ name: 'Shipping Protection', price: price.shipping_protection_price })
  }

  addOns.forEach((addon) => {
    if (!order.includes(addon.kind) && addon.kind !== ADDONS.VIP_SUBSCRIPTION) {
      elements.push(addon)
    }
  })

  return elements
}

const BoxOrderDetailsSummary = ({ product }) => {
  const classes = useStyles()

  const {
    price,
    processing,
    addOns,
  } = useVariableState(product.type, product.uuid)

  const activeProcessing = processing && processing.find((p) => !!p.default)

  const items = useMemo(() => (
    buildOrderDetailsList(addOns, activeProcessing, price)
  ), [addOns, activeProcessing, price.shipping_protection_price])

  return (
    <SummaryDetailsBox
      title={'Order Details'}
      editHref={''}
    >
      <List className={classes.listRoot}>
        {items.map(({ name, price, quantity }) => (
          <ListItem key={name} disableGutters className={classes.listItemRoot}>
            <ListItemText classes={{ primary: cl(classes.text, classes.name) }}>
              {name}{+quantity ? ` x ${quantity}` : ''}
            </ListItemText>
            <ListItemSecondaryAction className={cl(classes.text, classes.price)}>
              {+price ? `+$${formatNumber(price * (quantity ?? 1))}` : 'FREE'}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </SummaryDetailsBox>
  )
}

BoxOrderDetailsSummary.propTypes = {
  product: PropTypes.object,
}

export default BoxOrderDetailsSummary
