import {
  action,
  computed,
  thunk,
  thunkOn,
} from 'easy-peasy'
import debounce from 'lodash.debounce'

import { ADDONS } from 'common/utils/conciergeProduct.constants'
import request from 'common/utils/request'
import reset from 'artkive/stores/ecom/helpers/reset'
import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'

import BoxImg from 'images/ecom/checkout/Checout-Box-Thumbnail.jpg'

import { fetchBoxPrice as fetchPrice } from '../api/fetchPrice'
import customStorage from '../customStorageEngine'

import { DEFAULT_STATE as USER_STATE } from './user.store'

export const BOX_CHECKOUT_STEPS = {
  ORDER_DETAILS: 'processing',
  SHIPPING_AND_PAYMENT: 'payment',
}

const DEFAULT_STATE = {
  addOns: [],
  information: { ...USER_STATE.user },
  payment: {},
  price: {},
  pricePayload: {},
  product: {
    id: 0,
    image: BoxImg,
    previewImage: BoxImg,
    msrp: 0,
    price: 0,
    title: 'Artkive Box',
    type: BOX_PRODUCT_TYPE,
    isTaxable: true,
  },
  productMeta: {
    addons: [],
  },
  promoCode: null,
  processing: [],
  qty: 1,
  steps: [
    {
      alias: BOX_CHECKOUT_STEPS.ORDER_DETAILS,
      name: 'Order Details',
      index: 0,
      isActive: true,
      isComplete: false,
      isDirty: true,
      isVisible: true,
    },
    {
      alias: BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT,
      name: 'Shipping and Billing',
      index: 1,
      isActive: false,
      isComplete: false,
      isDirty: false,
      isVisible: true,
    },
  ],
  shippingProtection: false,
  shippingProtectionPrice: 0,
  tax: 0,
  confirmation: {},
}

const resetter = reset({ defaultState: DEFAULT_STATE, keys: ['promoCode'] })

const isFreeUsb = (price) => price?.promo_discount_details?.discount_method === DISCOUNT_METHODS.FREE_USB

const _setAddon = (state, { addon: { uuid, kind }, checked, quantity }) => {
  const selector = uuid ? (addon) => addon.uuid === uuid : (addon) => addon.kind === kind

  if (!checked) {
    state.addOns = state.addOns.filter((e) => !selector(e))
    return
  }

  let addOn = state.addOns.find(selector)
  if (!addOn) {
    const addOnDefinition = state.productMeta.addons.find(selector)
    addOn = {
      uuid: addOnDefinition.uuid,
      kind: addOnDefinition.kind,
      price: addOnDefinition.price,
      name: addOnDefinition.name,
    }
    state.addOns.push(addOn)
  }

  addOn.quantity = quantity ?? 1
}

const debounceFetchPrice = debounce((action, payload) => action(payload), 700)

export const STORE_NAME = 'boxStore'

const boxStore = {
  // state
  ...DEFAULT_STATE,

  // computed
  activeStep: computed((state) => state.steps.find((step) => !!step.isActive)),

  visibleSteps: computed((state) => state.steps.filter((step) => !!step.isVisible)),

  activeProcessing: computed((state) => state.processing.find((p) => p.default)),

  freeOrder: computed(({ price }) => Object.keys(price).length > 0 ? price.total <= 0 : false),

  fullName: computed(({ information }) => [information.firstName, information.lastName].filter(Boolean).join(' ')),

  hasSubscription: computed(({ addOns }) => (
    addOns.some(({ kind }) => kind === ADDONS.SUBSCRIPTION || kind === ADDONS.VIP_SUBSCRIPTION)
  )),

  getAddon: computed(({ addOns }) => (
    ({ uuid, kind }) => (
      addOns.find((addon) => uuid ? addon.uuid === uuid : addon.kind === kind)
    )
  )),

  freeAddons: computed((state) => ({
    [ADDONS.RETURN_USB]: isFreeUsb(state.price),
    [ADDONS.RETURN_ART]: +state.productMeta.addons.find((addon) => addon.kind === ADDONS.RETURN_ART)?.price === 0,
  })),

  returnArt: computed((state) => !!state.addOns.find((addon) => addon.kind === ADDONS.RETURN_ART)),

  returnUsb: computed((state) => !!state.addOns.find((addon) => addon.kind === ADDONS.RETURN_USB)),

  vipSubscription: computed((state) => !!state.addOns.find((addon) => addon.kind === ADDONS.VIP_SUBSCRIPTION)),

  shippingPrice: computed(
    ({ price }) => price.shipping_price + price.processing_price + price.shipping_protection_price,
  ),

  // actions
  setInformation: action((state, payload) => {
    state.information = { ...state.information, ...payload }
  }),

  setProductMeta: action((state, payload) => {
    state.productMeta = { ...state.productMeta, ...payload }
  }),

  // TODO: check do we need payment in store
  setPayment: action((state, payload) => {
    state.payment = { ...state.payment, ...payload }
  }),

  setPricePayload: action((state, payload) => {
    state.pricePayload = payload
  }),

  setPrice: action((state, payload) => {
    state.price = { ...state.price, ...payload }
  }),

  setProduct: action((state, payload) => {
    state.product = { ...state.product, ...payload }
  }),

  setConfirmation: action((state, payload) => {
    state.confirmation = { ...payload }
  }),

  setProcessingTime: action((state, name) => {
    state.processing = state.processing.map((time) => time.name === name
      ? { ...time, default: true }
      : { ...time, default: false })
  }),

  setProcessing: action((state, payload) => {
    state.processing = payload
  }),

  setPromoCode: action((state, payload) => {
    state.promoCode = payload
  }),

  setQty: action((state, payload) => {
    if (payload <= 0 || payload >= 50) throw new Error('Quantity must be greater than 0 and less than 50')

    state.qty = payload
  }),

  setAddon: action(_setAddon),

  setAddonPessimistic: action((state, { checked, kind }) => {
    if (!checked) {
      state.addOns = state.addOns.filter((addon) => addon.kind !== kind)
    } else if (checked && !state.addOns.find((addon) => addon.kind === kind)) {
      if ((state.productMeta?.addons?.length || 0) > 0) {
        _setAddon(state, { addon: { kind }, checked })
      } else {
        state.addOns.push({ kind })
      }
    }
  }),

  setVipSubscription: action((state, checked) => {
    _setAddon(state, { addon: { uuid: ADDONS.VIP_SUBSCRIPTION }, checked })
  }),

  setDefaultAddons: action((state) => {
    state.productMeta.addons.forEach((addon) => {
      if (+addon.price === 0) {
        state.addOns.find(({ uuid }) => uuid === addon.uuid) || state.addOns.push(addon)
      }
    })
  }),

  fulfillAddons: action((state) => {
    state.addOns.forEach((selectedAddon, index) => {
      if (selectedAddon.uuid) {
        return
      }

      const addonDefinition = state.productMeta.addons.find(({ kind }) => selectedAddon.kind === kind)
      if (addonDefinition) {
        state.addOns[index] = { ...selectedAddon, ...addonDefinition }
      }
    })
  }),

  clearAddons: action((state) => {
    state.addOns = []
  }),

  setShippingProtection: action((state, checked) => {
    state.shippingProtection = checked
  }),

  setShippingProtectionPrice: action((state, payload) => {
    state.shippingProtectionPrice = payload
  }),

  setStepActive: action((state, { stepName }) => {
    const alias = stepName.toLowerCase()

    state.steps = state.steps.map((step) => step.alias.toLowerCase() === alias
      ? { ...step, isActive: true }
      : { ...step, isActive: false })
  }),

  setStep: action((state, { stepName, payload }) => {
    const alias = stepName.toLowerCase()

    state.steps = state.steps.map((step) => step.alias.toLowerCase() === alias
      ? { ...step, ...payload }
      : step)
  }),

  // thunks
  setBoxCheckoutAddonsUrl: thunk(async ({ setAddonPessimistic }) => {
    const params = new URLSearchParams(location.search)

    if (params.get('returnArt') === 'true') {
      await setAddonPessimistic({ checked: true, kind: ADDONS.RETURN_ART })
    }
    if (params.get('returnUsb') === 'true') {
      await setAddonPessimistic({ checked: true, kind: ADDONS.RETURN_USB })
    }
  }),

  fetchPrice: thunk(async (actions, payload) => {
    try {
      actions.setPricePayload(payload)
      const data = await fetchPrice(payload)
      actions.setPrice(data)
    } catch (error) {
      return { error }
    }
  }),

  fetchProcessing: thunk(async (actions) => {
    try {
      const response = await request.get(Routing.processing_api_v2_orders_box_index())

      actions.setProcessing(response.data.data)
    } catch (error) {
      return { error }
    }
  }),

  // listeners
  onItemsChange: thunkOn(
    (actions) => [
      actions.setInformation,
      actions.setProcessingTime,
      actions.setProduct,
      actions.setPromoCode,
      actions.setQty,
      actions.setVipSubscription,
      actions.setShippingProtection,
      actions.setAddon,
      actions.setAddonPessimistic,
    ],
    async (actions, target, helpers) => {
      const {
        addOns,
        information,
        processing,
        promoCode,
        qty,
        shippingProtection,
        pricePayload,
        price,
        product,
      } = helpers.getState()

      const conciergeProcessing = processing.find((p) => p.default)

      if (!product.id)
        return

      const currentPricePayload = {
        concierge_product_id: product.id,
        add_ons: addOns,
        concierge_processing_id: conciergeProcessing?.id,
        promo_code: promoCode,
        quantity: qty,
        shipping_protection: shippingProtection,
        email: information?.email,
        shipping_state: information?.state,
        shipping_city: information.city,
        shipping_zip: information.zipCode,
        billing_zip: information.billingZipCode,
      }

      const isPriceLoaded = Object.keys(price).length
      if (JSON.stringify(currentPricePayload) !== JSON.stringify(pricePayload) || !isPriceLoaded) {
        console.debug('actions.fetchPrice')
        debounceFetchPrice(actions.fetchPrice, currentPricePayload)
      }
    },
  ),

  onSetProductMeta: thunkOn(
    (actions) => [actions.setProductMeta],
    async (actions) => {
      actions.setDefaultAddons()
      actions.fulfillAddons()
    },
  ),

  ...resetter,
}

export const persistOptions = { deny: ['payment', 'price'], storage: customStorage }

export default boxStore
