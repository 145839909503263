export const colors = {
  black: '#202126',
  text: {
    dark: '#353D41',
  },
  secondary: {
    light: '#E3F2FD',
    med: '#86D1FF',
    main: '#50bdff',
    dark: '#009FFF',
  },
  primary: {
    light: '#1D40CF',
    med: '#1936AF',
    main: '#142C90',
    dark: '#102371',
  },
  green: {
    700: '#208E46',
    light: '#E0F5E9',
    main: '#208E46',
  },
  darkgreen: {
    light: '#56eb95',
    main: '#0E793B',
    dark: '#073c1d',
  },
  blue: {
    900: '#102371',
    middle: '#DAEAFB',
  },
  darkblue: {
    light: '#5ac4fe',
    main: '#0174B2',
    dark: '#003959',
  },
  grey: {
    100: '#ECECEE',
    300: '#D8D9DD',
    700: '#646778',
    light: '#EBEBEB',
    med: '#D6D6D6',
    main: '#B1B1B1',
    deep: '#7E878E',
    dark: '#707070',
    backgroundDark: '#3D4B55',
  },
  orange: {
    light: '#fce5cf',
    main: '#F49220',
  },
  pink: {
    light: '#f8e8f1',
    main: '#DA8AB9',
  },
  purple: {
    light: '#EAE7F2',
    med: '#e2deed',
    main: '#DDD9EA',
    dark: '#7A68AE',
  },
  red: {
    light: '#F9D2DB',
    main: '#CF1B45',
    active: '#C63C2C',
  },
  yellow: {
    light: '#fef6e1',
    med: '#FFF2D1',
    main: '#FDE4A5',
    dark: '#FBCE5F',
  },
  white: {
    med: '#FAFAFA',
    main: '#FFFFFF',
    dark: '#F5F9FA',
  },
}

/**
 * NOTE: these are new colors that are used in the design but we aren't sure how them should be injected
 * into current pallet
 */
export const newColors = {
  light: {
    primary: {
      main: '#1976D2',
      dark: '#1565C0',
      light: '#42A5F5',
    },
    info: {
      dark: '#01579B',
    },
    text: {
      primary: '#000000',
    },
  },
}

const theme = {
  colors,
}

export default theme
